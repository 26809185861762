// import node modules
import React, { Component } from "react";
import { navigate, Link } from "gatsby";

class RecipeInfoContainer extends Component {
  pageNavigate = (pagePath) => {
    navigate(pagePath);
  };
  render() {
    const {
      recipeIngredients,
      recipeDirections,
      recipeDescription,
    } = this.props;
    return (
      <div className="gagl-ric-wrapper">
        <p className="gagl-ric-desc gagl-ric-content">{recipeDescription}</p>
        <div className="gagl-ric-ingredients">
          <h2 className="gagl-ric-header">INGREDIENTS</h2>
          <ul>
            {recipeIngredients.map((itemContent, index) => {
              return (
                <li key={"abc" + index} className="gagl-ric-content">
                  {itemContent}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="gagl-ric-directions">
          <h2 className="gagl-ric-header">DIRECTIONS</h2>
          <div>
            {recipeDirections.map((itemContent, index) => {
              return (
                <p key={"xyz" + index} className="gagl-ric-content">
                  {itemContent}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default RecipeInfoContainer;
