// import node modules
import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import Slider from "react-slick";
import { navigate, Link } from "gatsby";
import Img from "gatsby-image";
import { Redirect } from "@reach/router";

// import components
import RecipeInfoContainer from "../../../components/RecipeInfoContainer/RecipeInfoContainer.component";
import Util from "../../../Utilities/util";
import SEO from "../../../components/seo"

// import data
import { RECIPE_DETAILS_DATA } from "../../../data/Recipes/RecipeDetails.data";

function CustomNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="fa fa-angle-right gagl-custom-nxt-arrow"
      onClick={onClick}
    />
  );
}

function CustomPrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="fa fa-angle-left gagl-custom-prev-arrow"
      onClick={onClick}
    />
  );
}

class RecipeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    }
  }

  pageNavigate = (pagePath) => {
    navigate(pagePath);
  };
  createMarkup(tag) {
    return { __html: tag };
  }

  componentWillMount(){
    const { recipeId } = this.props;
    if(! RECIPE_DETAILS_DATA[recipeId])
    {
      this.setState({ redirect: true });
    }
  }

  render() {

    const { redirect } = this.state;

     if (redirect) {
       return <Redirect noThrow to='/404/' />;
     }
     
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <CustomNextArrow />,
      prevArrow: <CustomPrevArrow />,
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
      ],
    };
    const { recipeId } = this.props;
    const recipeMetaData = this.props.recipes;
    const RECIPE_DETAILS_DATA_COPY = RECIPE_DETAILS_DATA[recipeId] || {};
    const primaryRecipeMetaData = Util.getObjectFromSlug(
      recipeMetaData,
      recipeId
    );
    const more_recipe_arr = RECIPE_DETAILS_DATA_COPY.more_recipe;
    const siblingRecipeMetaData = Util.getSiblingsFromSlug(
      recipeMetaData,
      more_recipe_arr
    );

    return (
      <React.Fragment>
        <SEO title={this.props.recipeMetaTitle} description={this.props.recipeMetaDescription} />
        <section className="gagl-recipe-details-wrapper gagl-recipe-bg-color gagl-nav-top-space">
          <h1 className="product-title">
            {RECIPE_DETAILS_DATA_COPY.recipeName || ""}
          </h1>
          <Row className="gagl-carousel-container">
            <Col className="pl-lg-2 pl-md-1" xs="12" md="5" lg="5">
              <div className="gagl-recipe-main-img">
                <Img
                  className="gagl-rd-primary-crsl-img"
                  fluid={primaryRecipeMetaData.image}
                  alt={primaryRecipeMetaData.alt_text}
                ></Img>
              </div>
              <div className="d-none d-md-block d-lg-block">
                <div className="gagl-recipes-iteration-header mb-4">
                  more recipes
                </div>
                <div className="gagl-recipes-iteration-carousel">
                  <Slider {...settings}>
                    {siblingRecipeMetaData.map((sliderItem) => {
                      return (
                        <div
                          key={sliderItem.slug}
                          className="gagl-recipes-iteration-image"
                          onClick={() => {
                            this.pageNavigate(`/recipes/${sliderItem.slug}/`);
                          }}
                        >
                          <Img
                            className="w-100 h-100 mb-2"
                            fluid={{
                              ...sliderItem.image,
                              aspectRatio: 1,
                            }}
                            ref={this.ref}
                            imgStyle={{ objectFit: "cover" }}
                            alt={sliderItem.alt_text}
                          ></Img>
                          <Link
                            className="gagl-recipes-name"
                            dangerouslySetInnerHTML={this.createMarkup(
                              sliderItem.name
                            )}
                            to={`/recipes/${sliderItem.slug}/`}
                          ></Link>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                <div className="text-center mt-4 mb-4">
                  <button
                    className="gagl-btn gagl-btn-red"
                  >
                    <Link to="/recipes/">more recipes</Link>
                  </button>
                </div>
              </div>
            </Col>
            <Col xs="12" md="7" lg="7" className="p-0">
              <RecipeInfoContainer
                recipeDescription={RECIPE_DETAILS_DATA_COPY.recipeDescription}
                recipeIngredients={RECIPE_DETAILS_DATA_COPY.recipeIngredients}
                recipeDirections={RECIPE_DETAILS_DATA_COPY.recipeDirections}
              />
            </Col>
            <Col className="d-sm-block d-md-none d-lg-none" xs="12">
              <div className="mt-3">
                <div className="gagl-recipes-iteration-header mb-4">
                  more recipes
                </div>
                <div className="gagl-recipes-iteration-carousel">
                  <Slider {...settings}>
                    {siblingRecipeMetaData.map((sliderItem) => {
                      return (
                        <div
                          key={"b" + sliderItem.slug}
                          className="gagl-recipes-iteration-image"
                          onClick={() => {
                            this.pageNavigate(`/recipes/${sliderItem.slug}/`);
                          }}
                        >
                          <Img
                            className="w-100 h-100 mb-2"
                            fluid={{
                              ...sliderItem.image,
                            }}
                            alt={sliderItem.alt_text}
                          ></Img>
                          <Link
                            className="gagl-recipes-name"
                            dangerouslySetInnerHTML={this.createMarkup(
                              sliderItem.name
                            )}
                            to={`/recipes/${sliderItem.slug}/`}
                          ></Link>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                <div className="text-center mt-4 mb-4">
                  <button
                    className="gagl-btn gagl-btn-red"
                  >
                    <Link to="/recipes/">more recipes</Link>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </React.Fragment>
    );
  }
}

export default RecipeDetails;
